.card {
    width: 100%;
    height: 390px;
    cursor: pointer;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        transition:  all 1s linear;
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */

        &:hover {
            filter: none;
        }
    }

    .title {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 13px 7px;
        background-color: rgba(180, 14, 14, 0.884);
        color: white;
        text-align: center;
    }
}