.cursor {
  cursor: pointer;
}

.widget ul, .widget ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget ul > li, .widget ol>li {
  display: block;
  margin-bottom: 0.375rem;
}

.widget-light {
 
  .widget-title {
    color: #fff;
  }

  .widget-link {
    display: block;
    position: relative;
    padding: 0.25rem 0;
    transition: color .25s ease-in-out;
    color: #5a5b75;
    font-weight: 500;
    text-decoration: none;
    color: rgba(255,255,255,.7);
    font-weight: normal;
  }

}

.widget-title {
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
}


.fade-in-section {
    opacity: 0;
    transform: translateY(5vh);
    visibility: hidden;
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    animation-delay: 0.5s;
    will-change: opacity, visibility;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;

    .row {
      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.5s;
      }
    }
  }

.img-cover {
  height: 100%;
  margin: auto;
  object-fit: cover;
}

.img-contain {
  width: 100%;
  object-fit: contain;
}

.img-fill {
  width: 100%;
  object-fit: fill;
}

.loader {

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .spinner-1 {
    width:30px;
    height:30px;
    border-radius:50%;
    border:8px solid;
    border-color:lightblue;
    border-right-color: orange;
    animation:s1 1s infinite linear;
  }
  
}

@keyframes s1 {to{transform: rotate(1turn)}}
