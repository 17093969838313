.navbar-expand-lg {
  font-size: 0.9rem;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  color: #aeb8c0;

  .nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: white;
    white-space: nowrap;

    &>ul {
      display: flex;
      margin: 0;
      padding: 0;
    }

    a {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      font-weight: 500;
      font-size: 0.9rem;
    }

    .active {
      font-weight: 500;
      color: #343a40;
    }

    .icon {
      text-align: center;
      background-color: #677481;
      border-radius: 50%;
      padding: 4px 7px;
      margin-right: 5px;

    }

    .dropdown-item {
      a {
        text-decoration: none;
      }

      span {
        padding: 0 5px;
        color: #000;
        text-transform: uppercase;
      }
    }
  }

}

header {
  padding: 10px 0;
  margin-bottom: 15px;
  background-color: white;

  .logo {
    width: 200px;
  }
}

.sticky {
  width: 100%;
  z-index: 99;
  position: fixed;
  background-color: white;
  top: 0;
  box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.16);

  img {
    width: 100px;
  }

  .nav-primary {

    a {
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }

      &:focus {
        border-bottom: none;
      }
    }
  }
}

.nav-underline {

  .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.9rem;
    color: #6c757d;
  }

  ul {
    list-style-type: none;
  }

  .pill {
    color: #ffffff;
    border-radius: 18px;
    padding: 8px 14px 2px 14px;
    text-decoration: none;

  }

  .lang {
    text-transform: uppercase;
    margin: 12px;
    font-size: 0.8rem;
    color: #737c85;
    font-weight: 700;
  }

  .phone {
    background-color: #ba002c;
    color: #fff;
  }

  .email {
    padding: 10px;
    color: #343a40;
  }
}

.navbar-dark {

  .navbar-brand {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.55);

    &.active {
      color: white;
    }
  }
}

.nav-primary {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  white-space: nowrap;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;
      position: relative;

      ul {
        background: white;
        visibility: hidden;
        opacity: 0;
        min-width: 5rem;
        position: absolute;
        transition: all 0.5s ease;
        left: 0;
        display: none;
        z-index: 9999;

        border: .5px solid var(--border-secondary);
        border-radius: 16px;
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 4px 24px rgba(0, 0, 0, .16);
        padding: 40px 0 40px 64px;
        right: calc(100% - 228.33333px);
        width: 685px;
      }

      &:hover>ul,
      &:focus-within>ul,
      & ul:hover,
      & ul:focus {
        visibility: visible;
        opacity: 1;
        display: block
      }

      &:focus-within a {
        outline: none;
      }

      a {
        font-size: 0.78rem;
        font-weight: 600;
        margin: 0 5px;
        color: #666666;
        text-transform: uppercase;

        &:first-letter {
          text-transform: uppercase;
        }

        &:hover {
          color: rgb(160, 19, 26);
        }

        &:focus {
          color: rgb(160, 19, 26);
        }
      }

      .active {
        color: rgb(160, 19, 26);
      }
    }

  }

}


.hamburger {
  position: absolute;
  overflow-y: scroll;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(87, 87, 87);

  .nav-primary {

    ul {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;

      li {
        a {
          padding: 30px;
        }
      }
    }

  }
}

@media screen and (max-width: 1200px) {

  .nav-primary a {
    font-size: 0.68rem;
    margin: 0;
    padding: 0.5rem 0.6rem;
  }

}