$accordion-height: 450px;

.accordion-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(235, 235, 235, 0.932);
  height: $accordion-height;
}

.accordion {
  //display: contents;
  width: 100%;
  height: $accordion-height;
  overflow: hidden;
  margin: 0 0 30px 0;
  color: white;

  a {
    color: white;
  }

  ul {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;

    .vertical {
      padding: 5px;
      font-size: 14px;
      text-indent: 34px;
      text-align: left;
      color: #ffffff;
      background: #c3002f;
      width: 250px;
      transform-origin: left bottom;
      transform: rotate(-90deg) translateY(100%);

    }

    li {
      display: table-cell;
      vertical-align: bottom;
      position: relative;
      width: 16.666%;
      height: $accordion-height;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 500ms ease;
      //box-shadow: -9px 0px 4px -3px rgba(0, 0, 0, 0.59);

      .tab {
        display: block;
        overflow: hidden;
        height: $accordion-height;
        width: 100%;
        transition: all 500ms ease;
        background-color: rgba(0, 0, 0, 0.6);

        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

}


.accordion ul li .tab a {
  display: block;
  height: $accordion-height;
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 15px 20px;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  transition: all 200ms ease;
  border-left: 1px solid #c3002f;

}

.accordion ul li .tab a * {
  opacity: 0;
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  position: relative;
  z-index: 5;
  white-space: nowrap;
  overflow: hidden;
  transform: translateX(-20px);
  transition: all 400ms ease;
  transition-delay: 0.4s;
}

.accordion ul li .tab a h2 {
  text-overflow: clip;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2px;
  top: 80%;
  color: white;
}

.accordion ul li .tab a p {
  top: 81%;
  font-size: 18px;
}

.accordion ul li {
  width: 8%;
}

.accordion ul li.active {
  width: 60%;
  //box-shadow: -5px 1px 9px 5px rgba(0, 0, 0, 0.22);

  .vertical {
    opacity: 0;
    bottom: 50px;
  }
}

.accordion ul li.active a {
  border: none;
  background: rgba(0, 0, 0, 0.2);
}

.accordion ul li.active .tab {
  background: linear-gradient(47deg, rgba(10, 10, 10, 0.6) 0%, rgba(61, 61, 61, 0.2) 33%, rgba(84, 84, 84, 0) 100%);
}

.accordion ul li.active .vertical {
  display: none;
}


.accordion ul li.active a * {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@media screen and (max-width: 600px) {

  .accordion {
    height: auto;
  }

  .accordion ul li,
  .accordion ul li:hover,
  .accordion ul:hover li,
  .accordion ul:hover li:hover {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }

}