.slideshow-btn {
    background-color: transparent;
    background: none;
    border: none;
}

.carousel__dot { 
    border-radius: 4px;
    border: 2px solid #ddd;
}

.carousel__dot--selected {
    border: 2px solid rgb(60, 61, 68);
}

.slider-container {

    figure {
        border-radius: 4px !important;
        background: linear-gradient(90deg, rgba(10, 10, 10, 1) 0%, rgba(61, 61, 61, 0.2) 29%, rgba(84, 84, 84, 0) 100%);
    }

    .slider-title { 
        position: absolute;
        top: 50px;
        left: 50px;
        color: white;
        text-overflow: clip;
        font-size: 3.5vw;
        font-weight: 700;
        text-transform: uppercase;
    }

    .slider-text { 
        position: absolute;
        top: 130px;
        left: 50px;
        color: white;
        text-overflow: clip;
        font-size: 2.5vw;
    }

    .slider-disable {
        .slider-title {
            animation: 0.2s ease 0s normal forwards 1 fadeout;
        }   
        .slider-text {
            animation: 0.5s ease 0s normal forwards 1 fadeout;
        } 
    }

    .slider-active {
        .slider-title {
            animation: 1s ease 0s normal forwards 1 fadein;
        }
        .slider-text {
            animation: 1.4s ease 0s normal forwards 1 fadein;
        }
    }

    
    @keyframes fadein{
        0% { opacity:0; left: 20px; }
        66% { opacity:0; left: 20px;}
        100% { opacity:1; left:50px;}
    }

    @keyframes fadeout{
        0% { opacity:1;left:50px; }
        66% { opacity:0; left:50px;}
        100% { opacity:0; left: 20px;}
    }
    

    .thumb-group {
        display: flex;
        margin-top: 15px;
        width: 100%;
        justify-content: center;

        .thumb {
            display: block;
            width: 20px;
            height: 5px;
            margin: 0 5px;

            border:none;
            border-radius: 2px;
            color: white;
            font-size: 0.8rem;
            background: #c0c0c0;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
                filter: grayscale(1);
            }

            &:disabled {
                background-color: rgb(59, 59, 59);
            }

            &:hover {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                perspective: 1000px;
            }

            
        }
    }

    .btn-slider {
        position: absolute;
        top: 50%;
        border:none;
        background-color: white;
        color: rgb(51, 51, 51);
        width: 30px;
        height: 30px;
        transition:  all 0.3s linear;

        &:hover {
            background-color: rgb(255, 255, 255);
        }
    }

    .btn-next {
        right: -4px;
    }

    .btn-back {
        left: -4px;
    }


}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    
    30%, 50%, 70% {
      transform: translate3d(-2px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }
  }