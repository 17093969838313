.images_grid {
    display: grid;
    grid-template-columns: 4fr repeat(2, 1fr);
    grid-template-rows: 1fr 0.5fr 1.5fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    height: 480px;
    margin-bottom: 20px;

    figure {
        display: flex;
        align-items: end;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        background-color: white;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .title {
        width: -webkit-fill-available;
        height: fit-content;
        margin: 10px;
        padding: 10px;
        z-index: 6;
        left: 20px;
        bottom: 20px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        h1 {
            font-size: 1.8vw;
            color: #fff;
            margin: 0 0 10px 0;
            padding: 0;
            font-weight: 600;
        }

        h2 {
            font-size: calc(0.95vw - 5%);
            color: #fff;
            margin: 0;
            padding: 0;
            font-weight: 300;
            line-height: normal;
        }
    }
}

.product_grid_2col {
    grid-template-columns: 4fr repeat(1, 1fr);
}

.div1 {
    grid-area: 1 / 1 / 4 / 2;
}

.div1.dual {
    grid-area: 1 / 1 / 4 / 2;
}

.div2 {
    grid-area: 3 / 2 / 4 / 4;
}

.div2.dual {
    grid-area: 1 / 2 / 4 / 4;
}

.div3 {
    grid-area: 1 / 2 / 3 / 3;
}

.div4 {
    grid-area: 1 / 3 / 3 / 4;
}

.div2-2 {
    grid-area: 1 / 2 / 4 / 4;
}

.solo {
    grid-area: 1 / 1 / 4 / 4;
}

.dual {
    grid-area: 1 / 1 / 4 / 4;
}

@media screen and (max-width: 590px) {

    .images_grid {
        display: grid;
        grid-template-columns: 1;
        grid-template-rows: 1;
        grid-column-gap: 0;
        grid-row-gap: 0;

        .div1{grid-area: 1 / 4 / 4 / 1;}
        .div2 { display: none; }
        .div3 { display: none; }
        .div4 { display: none; }
        .div5 { display: none; }

        .title {
            height: fit-content;
            margin: 10px;
            padding: 10px;
            z-index: 6;
            left: 0px;
            top: 50px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 4px;

            h1 {
                font-size: 12vw;
            }

            h2 {
                font-size: calc(4vw - 5%);
            }
        }
    }
  
  }