.products {

    article {
        position: relative;
        padding: 10px;
        margin-bottom: 10px;
    }

    h3 {
        color: rgb(180, 14, 14);
        font-size: 1.1rem;
        line-height: 25px;
    }

    .description {
        font-size: 0.8rem;

    }

    figure {
        display: block;
        width: 100%;
        background-size: cover;
        transition: all 0.3s linear;

        &:hover {
            filter: none;
        }
    }

    .btn {
        position: absolute;
        bottom: 5px;
    }
}

.product {

    margin-bottom: 20px;

    h1 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        padding: 15px 0;
        font-weight: 700;
        color: rgb(80, 73, 73);
        //border-bottom: 1px solid rgb(230, 230, 230);
    }

    h2 {
        font-size: 1rem;
        line-height: 29px;
        margin-bottom: 30px;
        color: #5c5259;
    }



    .modal-btn {

        &:hover {

            &::before {
                opacity: 1;
                background-color: #52555cb2;
            }

            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }

        &::before {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: opacity .3s ease-in-out;
            background-color: rgba(43, 52, 69, .5);
            content: "";
            opacity: 0;
            z-index: 1;
        }

        &::after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2.875rem;
            height: 2.875rem;
            margin-top: -1.4375rem;
            margin-left: -1.4375rem;
            transform: scale(0.3);
            color: #fff;
            font-size: 1.75rem;
            text-align: center;
            background-position: center center;
            background-repeat: no-repeat;
            line-height: 2.875rem;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-plus-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
            content: "";
            z-index: 5;
            transition: all .3s ease-in-out;
            opacity: 0;
        }

    }


    .description {
        height: auto;
        font-size: 0.9rem;

        .description-title {
            padding: 20px;
            color: white;
            background-color: #f34320;
        }

        h2 {
            font-size: 1.3rem;
            line-height: 32px;
            margin-bottom: 30px;
            color: rgb(99, 99, 99);
        }

        h3 {
            font-size: 1rem;
            line-height: 30px;
            margin-bottom: 30px;
            text-transform: initial;
        }

        h4 {
            font-size: 1.1rem;
            font-weight: 700;
            margin-top: 30px;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        p {
            line-height: 24px;
        }

        ul {
            margin-bottom: 25px;

            li {
                margin: 10px 0;
            }
        }
    }
}