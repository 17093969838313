.home_grid {
    display: grid;
    grid-template-columns: 4.45fr repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    padding: 10px;

    div {
        border-radius: 8px;
        background-position: center center;
        background-size: 70%;
        background-size: cover;

        a {
            text-decoration: none;
        }

        .slide {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 22px;
            color: white;
            font-weight: 700;
        }

        .slide-red {
            background-color: rgba(199, 16, 16, 0.6);

            span {
                color: #fff;
            }
        }

        .slide-blue {
            background-color: rgba(21, 23, 40, 0.7);
        }

        .slide-hover {
            background: rgba(14, 12, 51, 0.8);
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            transform: translateX(-260px);
            opacity: 0;
            transition: transform 0.4s, opacity 1s;
        }

        &:hover .slide-hover {
            transform: translateX(0px);
            opacity: 0.8;
        }
    }

    .slideshow {
        grid-area: 1 / 1 / 4 / 2;

        .carousel__back-button {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 99;
            background-color: white;
            border-radius: 4px;
            border: none;
        }

        .carousel__next-button {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 99;
            background-color: white;
            border-radius: 4px;
            border: none;
        }


        .corner1 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-radius: 0;
            border-width: 100px 0 0 100px;
            border-color: transparent transparent transparent #4c102b;
        }

        .corner2 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-radius: 0;
            border-width: 150px 0 0 150px;
            border-color: transparent transparent transparent #721a42;
        }

        figure {
            display: block;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            position: relative;

            .overlay {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.305);
            }


            img {
                width: 100%;
            }

            h2 {
                position: absolute;
                bottom: 0;
                color: #fff;
            }
        }
    }

    .div2 {
        position: relative;
        overflow: hidden;
        grid-area: 1 / 2 / 2 / 3;
        aspect-ratio: 1 / 1;
        background-color: #6a0dadc5;
    }

    .div3 {
        grid-area: 1 / 3 / 2 / 4;
        background-color: #0d72adc5;
        aspect-ratio: 1 / 1;
    }

    .div4 {
        grid-area: 2 / 2 / 3 / 3;
        aspect-ratio: 1 / 1;

    }

    .div5 {
        aspect-ratio: 1 / 1;
        grid-area: 2 / 3 / 3 / 4;
        background-color: #ad250dc5;
        position: relative;

        video {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @keyframes swing {
        15% {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
        }
        30% {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }
        50% {
            -webkit-transform: translateY(3px);
            transform: translateY(3px);
        }
        65% {
            -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
        }
        80% {
            -webkit-transform: translateY(2px);
            transform: translateY(2px);
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    .swing:hover > span
    {
        -webkit-animation: swing 0.7s ease;
        animation: swing 0.7s ease;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

}

@media screen and (max-width: 1124px) {

    .home_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 2.5fr repeat(4, 1fr);
        grid-column-gap: 3px;
        grid-row-gap: 3px;

        .slideshow { grid-area: 1 / 1 / 2 / 5; }
        .div2 { grid-area: 2 / 1 / 4 / 2; }
        .div3 { grid-area: 2 / 2 / 4 / 3; }
        .div4 { grid-area: 4 / 1 / 6 / 2; }
        .div5 { grid-area: 4 / 2 / 6 / 3; }
    }
  
  }