.news {

    article {
        padding: 15px;
        margin-bottom: 15px;
    }

    img {
        width: 100%;
        height: inherit;
        object-fit: cover;
        margin-bottom: 20px;
    }
  
    .summary {
        font-size: 0.8rem;
        margin: 10px 0;
    }

    .date {
        color: #666;
        font-size: 0.7rem;
    }

    figure {
        display: block;
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        transition: all .7s;

        &:hover {
            transition: all 1s;
            background-size: 110%;
        }
    }

    a {
        text-decoration: none;
    }

    h3 {
        color: rgb(180, 14, 14);
        font-size: 1.1rem;
        line-height: 25px;
    }

    p {
        font-size: 0.8rem;
    }
  }